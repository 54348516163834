import React, { FC, useContext, useEffect, useState } from 'react'
import { PageTitle } from '@components/configs/PageTitle'
import SectionTitle from '@components/ui/sectionTitle'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { formatStrapiText, isAddressValid } from '@utils/methods'
import * as pageUtils from '@pages/auth/profile/__index.utils'
import { TAB } from '@pages/auth/profile/__index.utils'
import FormikErrorFocus from 'formik-error-focus'
import Text from '@components/ui/text'
import { joinClasses } from '@utils/styles'
import Modal from '@components/ui/modal'
import { useAccount, useMsal } from '@azure/msal-react'
import { Address, Contact, User } from '@services/models'
import {
	EMAIL_NOTIFICATION_OPTION,
	LIVING_IN_LAVAL,
	CommunicationMeanEnum,
	GenderEnum,
	LanguageEnum
} from '@services/constants'
import RequiredIcon from '@components/ui/requiredIcon'
import { Field, Form, Formik, getIn } from 'formik'
import { IMaskInput } from 'react-imask'
import MapForm from '@components/ui/map/MapForm'
import { AddressInfo } from '@components/ui/map/mapForm.interfaces'
import { ButtonLink } from '@components/ui/ButtonLink'
import Tooltip from '@components/ui/tooltip'
import { Colors } from '@utils/css-variables'
import Notification from '@components/ui/notification'
import { navigate } from '@components/ui/link'
import config from '@utils/config'
import Loader from '@components/ui/loader'
import DOMPurify from 'dompurify'
import * as formUtils from '@pages/auth/__index.utils'
import AddressValidationModal from '@components/ui/addressValidationModal'
import {
	getAddressByCoordinates,
	getDefaultCadastralNumber
} from '@components/ui/addressForm/__index.utils'
import MyProperty from '../my-property'
import { Dictionary } from 'typings/shared'

type props = {
	location: Location
}
interface FieldValueProp {
	(field: string, value: any, shouldValidate?: boolean | undefined): void
}

const Profile: FC<props> = ({ location }) => {
	const { pageData, setAuthUser, authUser, language } =
		useContext(AppStateContext)
	const [modalOpen, setModalOpen] = useState<boolean>(false)
	const [openValidationModal, setOpenValidationModal] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [activeTab, setActiveTab] = useState<string>('0')

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const { accounts } = useMsal() ?? []
	const [infoUpdated, setInfoUpdated] = useState(false)
	const account = useAccount(accounts[0] || {})

	const [edited, setEdited] = useState(true)
	const [addressSaved, setAddressSaved] = useState<Address>()
	const [proposedAddress, setProposedAddress] = useState<any>()
	const [hasCancel, setHasCancel] = useState<boolean>(false)
	const [hasLanguageChange, setHasLanguageChange] = useState<boolean>(false)

	const getActiveTab = (): string => {
		const tab: string = (localStorage.getItem(config.localStorage.activeTab) ||
			TAB.INFOS) as string

		return tab
	}

	useEffect(() => {
		if (!authUser) {
			setIsLoading(true)

			return
		}
		setAddressSaved(authUser?.profile?.address)
		setIsLoading(false)
	}, [authUser])

	useEffect(() => {
		setActiveTab(getActiveTab())
	}, [])

	useEffect(() => {
		setActiveTab(getActiveTab())
	}, [localStorage.activeTab])

	useEffect(() => {
		const linksContainer = document.getElementsByClassName('link-redirect')
		Array.prototype.forEach.call(linksContainer, (container: HTMLElement) => {
			const links = container.getElementsByTagName('a')
			Array.prototype.forEach.call(links, (link) => {
				link.setAttribute('target', '_blank')
			})
		})
	}, [edited])

	const handleOnChange = (e, valeur, setFieldValue) => {
		const user = authUser?.profile

		switch (valeur) {
			case 'firstName':
				setFieldValue('firstName', e)
				setEdited(user?.firstName != e)
				break

			case 'email':
				setFieldValue('email', e)
				setEdited(user?.email != e)
				break

			case 'emailNotification':
				setFieldValue('emailNotification', e)
				setEdited(user?.emailNotification != e)
				break

			case 'gender':
				setFieldValue('gender', e)
				setEdited(user?.gender != e)
				break

			case 'lastName':
				setFieldValue('lastName', e)
				setEdited(user?.lastName != e)
				break

			case 'mail':
				setFieldValue('mail', e)
				setEdited(user?.mail != e)
				break
			case 'mail2':
				setFieldValue('mail2', e)
				setEdited(user?.mail2 != e)
				break
			case 'mail3':
				setFieldValue('mail3', e)
				setEdited(user?.mail3 != e)
				break

			case 'phoneNumber':
				setFieldValue('phoneNumber', e)
				setEdited(user?.phoneNumber != e)
				break
			case 'phoneNumberEvening':
				setFieldValue('phoneNumberEvening', e)
				setEdited(user?.phoneNumberEvening != e)
				break
			case 'phoneNumberOther':
				setFieldValue('phoneNumberOther', e)
				setEdited(user?.phoneNumberOther != e)
				break
			case 'language':
				setFieldValue('language', e)
				setEdited(user?.language != e)
				break
			case 'preferredContactMethod':
				setFieldValue('preferredContactMethod', e)
				setEdited(user?.preferredContactMethod != e)
				break
			case 'gender':
				setFieldValue('gender', e)
				setEdited(user?.gender != e)
				break
			case 'emailNotification':
				setFieldValue('emailNotification', e)
				setEdited(user?.emailNotification != e)
				break

			case 'address.address':
				setFieldValue('address.address', e)
				setEdited(user?.address.address != e)
				break
			case 'address.inMunicipality':
				setFieldValue('address.inMunicipality', e)
				setEdited(user?.address.inMunicipality != e)
				break
			case 'address.city':
				setFieldValue('address.city', e)
				setEdited(user?.address.city != e)
				break
			case 'address.state':
				setFieldValue('address.state', e)
				setEdited(user?.address.state != e)
				break
			case 'address.postalCode':
				setFieldValue('address.postalCode', e)
				setEdited(user?.address.postalCode != e)
				break
			case 'address.country':
				setFieldValue('address.country', e)
				setEdited(user?.address.country != e)
				break
			case 'address.apartment':
				setFieldValue('address.apartment', e)
				setEdited(user?.address.apartment != e)
				break

			default:
				setEdited(false)
				break
		}
	}

	const formLabel = (
		labelText: string,
		hasError?: boolean,
		errorText?: string,
		required: boolean = true
	) => {
		return (
			<>
				<label
					className={joinClasses([
						formUtils.classes.formLabel,
						hasError ? formUtils.classes.textError : ''
					])}
				>
					{labelText}
					{required && <RequiredIcon />}
				</label>
				{hasError && errorText ? (
					<span
						className={joinClasses([
							formUtils.classes.textError,
							formUtils.classes.textDescription
						])}
					>
						{pageData.assets[errorText]}
					</span>
				) : null}
			</>
		)
	}

	const resetFields = (setFieldValue, isDefault) => {
		setFieldValue(
			'address.address',
			isDefault ? authUser?.profile?.address.address : ''
		)
		setFieldValue(
			'address.city',
			isDefault ? authUser?.profile?.address.city : ''
		)
		setFieldValue(
			'address.state',
			isDefault ? authUser?.profile?.address.state : ''
		)
		setFieldValue(
			'address.postalCode',
			isDefault ? authUser?.profile?.address.postalCode : ''
		)
		setFieldValue(
			'address.apartment',
			isDefault ? authUser?.profile?.address.apartment : ''
		)
		setFieldValue(
			'address.state',
			isDefault ? authUser?.profile?.address.state : ''
		)
		setFieldValue(
			'address.city',
			isDefault ? authUser?.profile?.address.city : ''
		)
	}

	const handleSubmit = async (contact: Contact) => {
		setIsLoading(true)

		if (!account) {
			// we must have an account, otherwise, we're not logged in and we should not be able to access this page.
			navigate(config.homepage.baseURL)

			throw new Error(
				'No account was found to perform a contact update for the user'
			)
		}

		try {
			setIsSubmitting(true)

			contact.address.prepareProperties()
			contact.applyAccountValues(account)

			await contact.saveAsync()
			const user: User = await User.buildAsync(account, contact)

			setAuthUser(user)

			// if (location.state?.form) {
			// 	navigate(location.state?.url + `?step=${location.state?.step}`, {
			// 		state: {
			// 			step: location.state?.step
			// 		}
			// 	})
			// }
			if (
				authUser?.profile?.language != contact.language &&
				contact.language === LanguageEnum.EN
			) {
				setHasLanguageChange(true)
			}
			setInfoUpdated(true)
		} catch (error: any) {
			// TODO: request a wireframe for this
			alert(error?.message)
			console.error('Backend Error: ', error?.message)

			throw new Error(error?.message)
		} finally {
			setIsSubmitting(false)
			setTimeout(() => setIsLoading(false), 200)
		}
	}

	if (isLoading) {
		return <Loader text={pageData?.assets?.loading} />
	}

	const saveContactInformation = (
		values: Contact,
		setFieldValue: FieldValueProp
	) => {
		if (
			values?.address.apartment !== null ||
			values?.address.apartment !== ''
		) {
			setFieldValue(
				'address.apartment',
				DOMPurify.sanitize(values?.address.apartment)
			)
			setAddressSaved(values?.address)
		}

		if (
			values.address.hasAddressEnteredManually ||
			values.address.inMunicipality === LIVING_IN_LAVAL.NO
		) {
			setFieldValue(
				'address.address',
				DOMPurify.sanitize(values?.address.address)
			)
			setFieldValue('address.city', DOMPurify.sanitize(values?.address.city))
			setFieldValue('address.state', DOMPurify.sanitize(values?.address.state))
			setFieldValue(
				'address.country',
				DOMPurify.sanitize(values?.address.country)
			)
			setAddressSaved(values?.address)
		}
	}

	// enum TAB {
	// 	INFOS = '0',
	// 	PREFERENCES = '1',
	// 	PROPERTY = '2'
	// }

	const onChangeActiveTab = (value) => {
		if (value === '') {
			return
		}

		setActiveTab(value)
		localStorage.setItem(config.localStorage.activeTab, value)
	}

	const isComunicationLanguageEnglish =
		hasLanguageChange && authUser?.profile?.language === LanguageEnum.EN

	const getActiveTabLabel = () => {
		switch (getActiveTab()) {
			case TAB.INFOS:
				return formatStrapiText(pageData.assets.homepage_myInfos)
			case TAB.PREFERENCES:
				return formatStrapiText(pageData.assets.header_my_preferences)
			case TAB.PROPERTY:
				return formatStrapiText(pageData.assets.header_dropdown_my_property)
		}
	}
	return (
		<>
			<PageTitle title={formatStrapiText(pageData.title)} />
			<SectionTitle title={getActiveTabLabel()!} />
			<div
				className={pageUtils.classes.tab}
				onClick={(e) => onChangeActiveTab(e.target.id)}
			>
				<div className={activeTab === TAB.INFOS ? 'active' : ''} id={TAB.INFOS}>
					{pageData.assets.homepage_myInfos}
				</div>
				<div
					className={activeTab === TAB.PREFERENCES ? 'active' : ''}
					id={TAB.PREFERENCES}
				>
					{pageData.assets.header_my_preferences}
				</div>
				<div
					className={activeTab === TAB.PROPERTY ? 'active' : ''}
					id={TAB.PROPERTY}
				>
					{pageData.assets.header_dropdown_my_property}
				</div>
			</div>
			<section className={pageUtils.classes.section}>
				{infoUpdated && (
					<div>
						<Notification
							onClickCancelBtn={() => {
								setHasLanguageChange(false)
								setInfoUpdated(false)
							}}
							showExitBtn
							type="success"
							text={
								isComunicationLanguageEnglish
									? pageData.assets?.label_communication_language_changed
									: pageData?.assets
											?.profilePage_your_personal_information_has_been_updated
							}
						/>
					</div>
				)}
				<div
					className={
						activeTab === TAB.PROPERTY || activeTab === TAB.PREFERENCES
							? ''
							: pageUtils.classes.container
					}
				>
					<div className={pageUtils.classes.column1}>
						<Formik
							initialValues={authUser?.profile || new Contact()}
							validationSchema={pageUtils.SignupSchema}
							onSubmit={(values, { setSubmitting }) => {
								if (!hasCancel) {
									setSubmitting(true)
									handleSubmit(values)
								}
							}}
							onReset={() => {
								navigate(config.auth.profile.baseUrl)
								setEdited(false)
							}}
						>
							{({ errors, touched, setFieldValue, values, resetForm }) => (
								<>
									<Form>
										{activeTab === TAB.INFOS && (
											<>
												<h3 className={pageUtils.classes.h3}>
													{formatStrapiText(
														pageData?.assets?.label_identification
													)}
												</h3>
												<div>
													<div className={pageUtils.classes.fieldContainers}>
														<fieldset>
															<Field name="gender" as="select">
																{({ field }) => (
																	<>
																		<label
																			className={formUtils.classes.formLabel}
																		>
																			{formatStrapiText(
																				pageData.assets?.label_greetings
																			)}
																			<RequiredIcon />
																		</label>
																		<select
																			className={joinClasses([
																				pageUtils.classes.select
																			])}
																			required
																			{...field}
																			onChange={(e) =>
																				handleOnChange(
																					e.target.value,
																					'gender',
																					setFieldValue
																				)
																			}
																		>
																			<option value={GenderEnum.FEMALE}>
																				{formatStrapiText(
																					pageData.assets?.label_greetWoman
																				)}
																			</option>
																			<option value={GenderEnum.MALE}>
																				{formatStrapiText(
																					pageData.assets?.label_greetMan
																				)}
																			</option>
																		</select>
																	</>
																)}
															</Field>
														</fieldset>
													</div>
													<div className={pageUtils.classes.fieldContainers}>
														<fieldset>
															<Field name="firstName">
																{({ field }) => (
																	<>
																		{formLabel(
																			formatStrapiText(
																				pageData.assets?.label_firstName
																			),
																			!!errors.firstName && touched.firstName,
																			errors.firstName
																		)}
																		<IMaskInput
																			mask={formUtils.MASKS.names}
																			autoComplete="given-name"
																			minLength={2}
																			maxLength={50}
																			className={joinClasses([
																				formUtils.classes.formInput,
																				errors.firstName && touched.firstName
																					? formUtils.classes.inputError
																					: ''
																			])}
																			placeholder=""
																			onAccept={(e) => {
																				handleOnChange(
																					e,
																					'firstName',
																					setFieldValue
																				)
																			}}
																			{...field}
																		/>
																	</>
																)}
															</Field>
														</fieldset>
														<fieldset>
															<Field name="lastName">
																{({ field }) => (
																	<>
																		{formLabel(
																			formatStrapiText(
																				pageData.assets?.label_lastName
																			),
																			!!errors.lastName && touched.lastName,
																			errors.lastName
																		)}
																		<IMaskInput
																			mask={formUtils.MASKS.names}
																			autoComplete="family-name"
																			minLength={2}
																			maxLength={50}
																			className={joinClasses([
																				formUtils.classes.formInput,
																				errors.lastName && touched.lastName
																					? formUtils.classes.inputError
																					: ''
																			])}
																			placeholder=""
																			onAccept={(e) => {
																				handleOnChange(
																					e,
																					'lastName',
																					setFieldValue
																				)
																			}}
																			{...field}
																		/>
																	</>
																)}
															</Field>
														</fieldset>
													</div>
													<div>
														<fieldset>
															<label style={{ display: 'flex' }}>
																<span>
																	{formatStrapiText(
																		pageData?.assets?.label_email
																	)}
																</span>
																<div
																	className={pageUtils.classes.profileTooltip}
																>
																	<Tooltip
																		showInfo
																		infoLabel={
																			pageData?.assets
																				?.profilePage_if_you_wish_to_change_the_email_address_associated_with_your_account_please_contact_311
																		}
																	/>
																</div>
															</label>
															<span>{authUser?.profile?.email}</span>
														</fieldset>
													</div>
												</div>

												<h3 className={pageUtils.classes.h3}>
													{formatStrapiText(pageData?.assets?.label_address)}
												</h3>
												<div>
													<p
														style={{
															minHeight: authUser?.profile?.address.apartment
																? '57px'
																: '38px'
														}}
													>
														{addressSaved?.address}
														<br />
														{addressSaved?.city}
														{addressSaved?.state
															? ` (${addressSaved?.state})`
															: ''}{' '}
														{addressSaved?.postalCode}
														<br />
														{addressSaved?.apartment
															? `${formatStrapiText(
																	pageData.assets?.label_apartment_office
															  )} ${addressSaved?.apartment}`
															: ''}
													</p>
													<a
														className={pageUtils.classes.buttonOutline}
														onClick={() => {
															// setFieldValue('address.apartment', '')
															setModalOpen(true)
														}}
													>
														{formatStrapiText(
															pageData?.assets?.profilePage_change_address
														)}
													</a>

													<Modal
														title={formatStrapiText(
															pageData?.assets?.label_address
														)}
														onPrimaryButton={async () => {
															if (
																values.address.inMunicipality &&
																values.address.hasAddressEnteredManually
															) {
																const { data } =
																	await getDefaultCadastralNumber(
																		values?.address.address
																	)

																const enteredAddress = DOMPurify.sanitize(
																	values?.address.address
																)
																const shouldSaveAddress = isAddressValid(
																	enteredAddress,
																	data?.features?.[0].place_name
																)

																if (!shouldSaveAddress) {
																	setModalOpen(false)
																	setProposedAddress(data?.features?.[0])
																	setOpenValidationModal(true)
																	return
																}
															}

															setModalOpen(false)
															saveContactInformation(values, setFieldValue)
														}}
														primaryButtonText={formatStrapiText(
															pageData?.assets?.label_modify
														)}
														cancelButtonText={formatStrapiText(
															pageData?.assets?.cancel
														)}
														onCancelBtn={() => {}}
														onCloseModal={() => {
															resetFields(setFieldValue, true)
															setModalOpen(false)
															setEdited(false)
														}}
														onSetIsOpen={() => {}}
														isOpen={modalOpen}
													>
														<fieldset className={formUtils.classes.fieldSet}>
															<div>
																{values?.address.inMunicipality && (
																	<Notification
																		text={formatStrapiText(
																			pageData.assets
																				?.profilePage_address_information
																		)}
																		type="info"
																		hasHtml
																	/>
																)}
																<div
																	className={formUtils.classes.addressWrapper}
																>
																	<Field name="address.inMunicipality">
																		{() => (
																			<>
																				<div
																					className={
																						formUtils.classes.radioWrapper
																					}
																				>
																					<input
																						type="radio"
																						id="inLaval"
																						value={`${LIVING_IN_LAVAL.YES}`}
																						className={
																							formUtils.classes.radioInput
																						}
																						checked={
																							values.address.inMunicipality ===
																							LIVING_IN_LAVAL.YES
																						}
																						onChange={(e) => {
																							setFieldValue(
																								'address.inMunicipality',
																								LIVING_IN_LAVAL.YES
																							)
																							handleOnChange(
																								LIVING_IN_LAVAL.YES,
																								'address.inMunicipality',
																								setFieldValue
																							)

																							resetFields(
																								setFieldValue,
																								authUser?.profile?.address
																									.inMunicipality ===
																									LIVING_IN_LAVAL.YES
																							)
																						}}
																					/>
																					<label
																						htmlFor="inLaval"
																						className={
																							formUtils.classes.radioLabel
																						}
																					>
																						{formatStrapiText(
																							pageData.assets?.label_liveInLaval
																						)}
																					</label>
																				</div>
																				<div
																					className={
																						formUtils.classes.radioWrapper
																					}
																				>
																					<input
																						type="radio"
																						id="notInLaval"
																						value={`${LIVING_IN_LAVAL.NO}`}
																						className={
																							formUtils.classes.radioInput
																						}
																						checked={
																							values.address.inMunicipality ===
																							LIVING_IN_LAVAL.NO
																						}
																						onChange={() => {
																							setFieldValue(
																								'address.inMunicipality',
																								LIVING_IN_LAVAL.NO
																							)
																							handleOnChange(
																								LIVING_IN_LAVAL.NO,
																								'address.inMunicipality',
																								setFieldValue
																							)
																							resetFields(
																								setFieldValue,
																								authUser?.profile?.address
																									.inMunicipality ===
																									LIVING_IN_LAVAL.NO
																							)
																						}}
																					/>
																					<label
																						htmlFor="notInLaval"
																						className={
																							formUtils.classes.radioLabel
																						}
																					>
																						{formatStrapiText(
																							pageData.assets
																								?.label_doNotLiveInLaval
																						)}
																					</label>
																				</div>
																			</>
																		)}
																	</Field>
																</div>
																{values.address.inMunicipality !==
																	LIVING_IN_LAVAL.NO && (
																	<div style={{ marginBottom: '-30px' }}>
																		<label
																			className={formUtils.classes.formLabel}
																			style={{ marginBottom: '10px' }}
																		>
																			{formatStrapiText(
																				pageData.assets?.label_searchAddress
																			)}
																		</label>
																		<div
																			style={{
																				position: 'relative',
																				top: '-15px'
																			}}
																		>
																			<MapForm
																				classNames={{
																					address: joinClasses([
																						formUtils.classes.formInput,
																						values.address
																							.hasAddressEnteredManually
																							? formUtils.classes
																									.searchAddressDisabled
																							: ''
																					])
																				}}
																				timer={100}
																				onAddressChange={(
																					address: AddressInfo
																				) => {
																					setFieldValue(
																						'address.address',
																						address.name
																					)
																					setFieldValue(
																						'address.coordinates',
																						address.coordinates
																					)
																					setFieldValue(
																						'address.postalCode',
																						formUtils.formatPostalCode(
																							address.postalCode
																						)
																					)
																					setEdited(true)
																				}}
																				isAddressInputDisabled={
																					values.address
																						.hasAddressEnteredManually
																				}
																			/>
																		</div>
																		<p
																			style={{ marginTop: '15px' }}
																			className={formUtils.classes.helpText}
																		>
																			{!values.address
																				.hasAddressEnteredManually && (
																				<>
																					{formatStrapiText(
																						pageData.assets
																							?.description_addressNotFound
																					)}{' '}
																				</>
																			)}
																			<a
																				href="#"
																				onClick={(e) => {
																					e.preventDefault()
																					setFieldValue(
																						'address.hasAddressEnteredManually',
																						!values.address
																							.hasAddressEnteredManually
																					)
																					setFieldValue('address.address', '')
																					setFieldValue('address.city', '')
																					setFieldValue(
																						'address.postalCode',
																						''
																					)
																				}}
																				style={{ color: Colors.secondary }}
																			>
																				{formatStrapiText(
																					values.address
																						.hasAddressEnteredManually
																						? pageData.assets
																								?.description_cancelManualSearch
																						: pageData.assets
																								?.description_manualSearch
																				)}
																			</a>
																		</p>
																	</div>
																)}
															</div>
															<div className={formUtils.classes.inputBlock}>
																{(values.address.hasAddressEnteredManually ||
																	values.address.inMunicipality ===
																		LIVING_IN_LAVAL.NO) && (
																	<Field name="address.address">
																		{({ field }) => (
																			<div
																				className={
																					formUtils.classes.responsiveContainer
																				}
																			>
																				{formLabel(
																					formatStrapiText(
																						pageData.assets?.label_address
																					),
																					getIn(errors, 'address.address') &&
																						getIn(touched, 'address.address'),
																					getIn(errors, 'address.address'),
																					false
																				)}
																				<input
																					type="text"
																					autoCorrect="off"
																					autoCapitalize="off"
																					placeholder=""
																					spellCheck="false"
																					className={joinClasses([
																						formUtils.classes.formInput,
																						getIn(errors, 'address.address') &&
																						getIn(touched, 'address.address')
																							? formUtils.classes.inputError
																							: ''
																					])}
																					{...field}
																					onChange={(e) =>
																						handleOnChange(
																							e.target.value,
																							'address.address',
																							setFieldValue
																						)
																					}
																				/>
																			</div>
																		)}
																	</Field>
																)}
																<Field name="address.apartment">
																	{({ field }) => (
																		<div
																			className={
																				formUtils.classes.responsiveContainer
																			}
																		>
																			<label
																				className={formUtils.classes.formLabel}
																			>
																				{formatStrapiText(
																					pageData.assets?.apartment_label
																				)}
																			</label>
																			<input
																				type="text"
																				placeholder=""
																				autoComplete="off"
																				className={formUtils.classes.formInput}
																				{...field}
																				onChange={(e) =>
																					handleOnChange(
																						e.target.value,
																						'address.apartment',
																						setFieldValue
																					)
																				}
																			/>
																		</div>
																	)}
																</Field>
															</div>
															{(values.address.hasAddressEnteredManually ||
																values.address.inMunicipality ===
																	LIVING_IN_LAVAL.NO ||
																values.address.hasAddressEnteredManually) && (
																<div className={formUtils.classes.inputBlock}>
																	<Field name="address.city">
																		{({ field }) => (
																			<div
																				className={
																					formUtils.classes.responsiveContainer
																				}
																			>
																				{formLabel(
																					formatStrapiText(
																						pageData.assets?.label_city
																					),
																					getIn(errors, 'address.city') &&
																						getIn(touched, 'address.city'),
																					getIn(errors, 'address.city'),
																					false
																				)}
																				<input
																					type="text"
																					placeholder=""
																					autoComplete="address-level2"
																					className={joinClasses([
																						formUtils.classes.formInput,
																						getIn(errors, 'address.city') &&
																						getIn(touched, 'address.city')
																							? formUtils.classes.inputError
																							: ''
																					])}
																					{...field}
																					onChange={(e) =>
																						handleOnChange(
																							e.target.value,
																							'address.city',
																							setFieldValue
																						)
																					}
																				/>
																			</div>
																		)}
																	</Field>
																	<Field name="address.state">
																		{({ field }) => {
																			return (
																				<div
																					className={
																						formUtils.classes
																							.responsiveContainer
																					}
																				>
																					{formLabel(
																						formatStrapiText(
																							pageData.assets?.label_state
																						),
																						getIn(errors, 'address.state') &&
																							getIn(touched, 'address.state'),
																						getIn(errors, 'address.state'),
																						false
																					)}
																					<input
																						type="text"
																						placeholder=""
																						autoComplete="address-level1"
																						className={joinClasses([
																							formUtils.classes.formInput,
																							getIn(errors, 'address.state') &&
																							getIn(touched, 'address.state')
																								? formUtils.classes.inputError
																								: ''
																						])}
																						{...field}
																						onChange={(e) =>
																							handleOnChange(
																								e.target.value,
																								'address.state',
																								setFieldValue
																							)
																						}
																					/>
																				</div>
																			)
																		}}
																	</Field>
																	<Field name="address.postalCode">
																		{({ field }) => (
																			<div
																				className={
																					formUtils.classes.responsiveContainer
																				}
																			>
																				{formLabel(
																					formatStrapiText(
																						pageData.assets?.label_postalCode
																					),
																					getIn(errors, 'address.postalCode') &&
																						getIn(
																							touched,
																							'address.postalCode'
																						),
																					getIn(errors, 'address.postalCode'),
																					false
																				)}
																				<IMaskInput
																					mask={formUtils.MASKS.postalCode}
																					prepare={(str: string) =>
																						str.toUpperCase()
																					}
																					className={joinClasses([
																						formUtils.classes.formInput,
																						getIn(
																							errors,
																							'address.postalCode'
																						) &&
																						getIn(touched, 'address.postalCode')
																							? formUtils.classes.inputError
																							: ''
																					])}
																					autoComplete="postal-code"
																					placeholder="A1A 1A1"
																					{...field}
																					onAccept={(e) =>
																						handleOnChange(
																							e,
																							'address.postalCode',
																							setFieldValue
																						)
																					}
																				/>
																			</div>
																		)}
																	</Field>
																	<Field name="address.country">
																		{({ field }) => (
																			<div
																				className={
																					formUtils.classes.responsiveContainer
																				}
																			>
																				{formLabel(
																					formatStrapiText(
																						pageData.assets?.label_country
																					),
																					getIn(errors, 'address.country') &&
																						getIn(touched, 'address.country'),
																					getIn(errors, 'address.country'),
																					false
																				)}
																				<input
																					type="text"
																					placeholder=""
																					autoComplete="country-name"
																					className={joinClasses([
																						formUtils.classes.formInput,
																						getIn(errors, 'address.country') &&
																						getIn(touched, 'address.country')
																							? formUtils.classes.inputError
																							: ''
																					])}
																					{...field}
																					onChange={(e) =>
																						handleOnChange(
																							e.target.value,
																							'address.country',
																							setFieldValue
																						)
																					}
																				/>
																			</div>
																		)}
																	</Field>
																</div>
															)}
														</fieldset>
													</Modal>
													<AddressValidationModal
														currentAddress={{
															address: values?.address.address,
															city: values?.address.city,
															postalCode: values?.address.postalCode
														}}
														proposedAddress={proposedAddress}
														isModalOpen={openValidationModal}
														onCloseModal={() => {
															saveContactInformation(values, setFieldValue)
															setAddressSaved({
																...values?.address,
																hasAddressEnteredManually: true
															} as Address)
															setOpenValidationModal(false)
														}}
														onPrimaryButton={() => {
															setFieldValue(
																'address.apartment',
																DOMPurify.sanitize(values?.address.apartment)
															)

															setFieldValue(
																'address.address',
																DOMPurify.sanitize(proposedAddress?.place_name)
															)
															setFieldValue(
																'address.city',
																DOMPurify.sanitize(values?.address.city)
															)
															setFieldValue(
																'address.state',
																DOMPurify.sanitize(values?.address.state)
															)
															setFieldValue(
																'address.postalCode',
																DOMPurify.sanitize(
																	proposedAddress?.properties.postal_code
																)
															)
															setFieldValue(
																'address.country',
																DOMPurify.sanitize(values?.address.country)
															)
															setFieldValue(
																'address.hasAddressEnteredManually',
																false
															)
															setAddressSaved({
																...values?.address,
																address: proposedAddress?.place_name,
																postalCode:
																	proposedAddress?.properties.postal_code,
																hasAddressEnteredManually: false
															} as Address)
															setOpenValidationModal(false)
														}}
														onCancelBtn={() => {}}
													/>
												</div>

												<h3 className={pageUtils.classes.h3}>
													{formatStrapiText(pageData?.assets?.label_telephones)}
												</h3>
												<div>
													<p>
														{formatStrapiText(
															pageData?.assets
																?.profilePage_please_enter_at_least_one_phone_number
														)}
													</p>
													<div className={pageUtils.classes.fieldContainers2}>
														<Field name="phoneNumber">
															{({ field }) => (
																<div>
																	{formLabel(
																		formatStrapiText(
																			pageData.assets?.label_telephoneDay
																		),
																		!!errors.phoneNumber && touched.phoneNumber,
																		errors.phoneNumber
																	)}
																	<IMaskInput
																		mask={formUtils.MASKS.phoneNumber}
																		autoComplete="tel-national"
																		minLength={10}
																		maxLength={14}
																		className={joinClasses([
																			formUtils.classes.formInput,
																			errors.phoneNumber && touched.phoneNumber
																				? formUtils.classes.inputError
																				: ''
																		])}
																		placeholder="(000) 000-0000"
																		type="tel"
																		onAccept={(e) => {
																			handleOnChange(
																				e,
																				'phoneNumber',
																				setFieldValue
																			)
																		}}
																		{...field}
																	/>
																</div>
															)}
														</Field>
														<Field name="mail">
															{({ field }) => (
																<div>
																	{formLabel(
																		formatStrapiText(
																			pageData.assets?.label_mail
																		),
																		!!errors.mail3 && touched.mail3,
																		errors.mail3,
																		false
																	)}
																	<IMaskInput
																		mask={formUtils.MASKS.mail}
																		autoComplete="poste"
																		minLength={4}
																		maxLength={14}
																		className={joinClasses([
																			formUtils.classes.formInput,
																			errors.mail && touched.mail
																				? formUtils.classes.inputError
																				: ''
																		])}
																		type="text"
																		onAccept={(e) => {
																			handleOnChange(e, 'mail', setFieldValue)
																		}}
																		{...field}
																	/>
																</div>
															)}
														</Field>
													</div>
													<div className={pageUtils.classes.fieldContainers2}>
														<Field name="phoneNumberEvening">
															{({ field }) => (
																<div>
																	{formLabel(
																		formatStrapiText(
																			pageData.assets?.label_telephoneEvening
																		),
																		!!errors.phoneNumberEvening &&
																			touched.phoneNumberEvening,
																		errors.phoneNumberEvening,
																		false
																	)}
																	<IMaskInput
																		mask={formUtils.MASKS.phoneNumber}
																		autoComplete="tel-national"
																		minLength={10}
																		maxLength={14}
																		className={joinClasses([
																			formUtils.classes.formInput,
																			errors.phoneNumberEvening &&
																			touched.phoneNumberEvening
																				? formUtils.classes.inputError
																				: ''
																		])}
																		placeholder="(000) 000-0000"
																		type="tel"
																		onAccept={(e) => {
																			handleOnChange(
																				e,
																				'phoneNumberEvening',
																				setFieldValue
																			)
																		}}
																		{...field}
																	/>
																</div>
															)}
														</Field>
														<Field name="mail2">
															{({ field }) => (
																<div>
																	{formLabel(
																		formatStrapiText(
																			pageData.assets?.label_mail
																		),
																		!!errors.mail2 && touched.mail2,
																		errors.mail2,
																		false
																	)}
																	<IMaskInput
																		mask={formUtils.MASKS.mail}
																		autoComplete="poste"
																		minLength={4}
																		maxLength={14}
																		className={joinClasses([
																			formUtils.classes.formInput,
																			errors.mail2 && touched.mail2
																				? formUtils.classes.inputError
																				: ''
																		])}
																		type="text"
																		onAccept={(e) => {
																			handleOnChange(e, 'mail2', setFieldValue)
																		}}
																		{...field}
																	/>
																</div>
															)}
														</Field>
													</div>
													<div className={pageUtils.classes.fieldContainers2}>
														<Field name="phoneNumberOther">
															{({ field }) => (
																<div>
																	{formLabel(
																		formatStrapiText(
																			pageData.assets?.label_telephoneOther
																		),
																		!!errors.phoneNumberOther &&
																			touched.phoneNumberOther,
																		errors.phoneNumberOther,
																		false
																	)}
																	<IMaskInput
																		mask={formUtils.MASKS.phoneNumber}
																		autoComplete="tel-national"
																		minLength={10}
																		maxLength={14}
																		className={joinClasses([
																			formUtils.classes.formInput,
																			errors.phoneNumberOther &&
																			touched.phoneNumberOther
																				? formUtils.classes.inputError
																				: ''
																		])}
																		placeholder="(000) 000-0000"
																		type="tel"
																		onAccept={(e) => {
																			handleOnChange(
																				e,
																				'phoneNumberOther',
																				setFieldValue
																			)
																		}}
																		{...field}
																	/>
																</div>
															)}
														</Field>
														<Field name="mail3">
															{({ field }) => (
																<div>
																	{formLabel(
																		formatStrapiText(
																			pageData.assets?.label_mail
																		),
																		!!errors.mail3 && touched.mail3,
																		errors.mail3,
																		false
																	)}
																	<IMaskInput
																		mask={formUtils.MASKS.mail}
																		autoComplete="poste"
																		minLength={4}
																		maxLength={14}
																		className={joinClasses([
																			formUtils.classes.formInput,
																			errors.mail3 && touched.mail3
																				? formUtils.classes.inputError
																				: ''
																		])}
																		type="text"
																		onAccept={(e) => {
																			handleOnChange(e, 'mail3', setFieldValue)
																		}}
																		{...field}
																	/>
																</div>
															)}
														</Field>
													</div>
												</div>
											</>
										)}
										{activeTab === TAB.PREFERENCES && (
											<div className="noBackground">
												<div className={pageUtils.classes.row}>
													<div
														className={joinClasses([pageUtils.classes.large])}
													>
														<section className={pageUtils.classes.section2}>
															<h3 className={pageUtils.classes.h3}>
																{formatStrapiText(
																	pageData?.assets?.page_preferences_my_prefs
																)}
															</h3>
															<div>
																<fieldset>
																	<Field name="language">
																		{({ field }) => (
																			<>
																				<label
																					htmlFor="communication-language"
																					className={pageUtils.classes.noMargin}
																				>
																					<Text
																						content={
																							pageData?.assets
																								?.label_communication_language
																						}
																					/>
																				</label>
																				<select
																					className={joinClasses([
																						pageUtils.classes.select,
																						pageUtils.classes.select2
																					])}
																					defaultValue={LanguageEnum.FR}
																					{...field}
																					onChange={(e) =>
																						handleOnChange(
																							e.target.value,
																							'language',
																							setFieldValue
																						)
																					}
																				>
																					<option value={LanguageEnum.FR}>
																						{formatStrapiText(
																							pageData?.assets?.label_french
																						)}
																					</option>
																					<option value={LanguageEnum.EN}>
																						{formatStrapiText(
																							pageData?.assets?.label_english
																						)}
																					</option>
																				</select>
																				{values.language == LanguageEnum.EN && (
																					<span
																						className={joinClasses([
																							pageUtils.classes.text,
																							'link-redirect'
																						])}
																					>
																						<Text
																							content={
																								pageData.assets
																									?.label_notice_from_the_french_charter
																							}
																						/>
																					</span>
																				)}
																			</>
																		)}
																	</Field>
																</fieldset>
																<fieldset>
																	<Field name="preferredContactMethod">
																		{({ field }) => (
																			<>
																				<label
																					htmlFor="communication-mean"
																					className={pageUtils.classes.noMargin}
																				>
																					<Text
																						content={
																							pageData?.assets
																								?.profilePage_what_is_your_preferred_method_of_communication_if
																						}
																					/>
																				</label>
																				<select
																					className={joinClasses([
																						pageUtils.classes.select,
																						pageUtils.classes.select2
																					])}
																					defaultValue={
																						CommunicationMeanEnum.MON_DOSSIER
																					}
																					{...field}
																					onChange={(e) =>
																						handleOnChange(
																							e.target.value,
																							'preferredContactMethod',
																							setFieldValue
																						)
																					}
																				>
																					<option
																						value={
																							CommunicationMeanEnum.NO_PREFERENCE
																						}
																					>
																						{formatStrapiText(
																							pageData?.assets
																								?.label_no_preference
																						)}
																					</option>
																					<option
																						value={
																							CommunicationMeanEnum.MON_DOSSIER
																						}
																					>
																						{formatStrapiText(
																							pageData?.assets
																								?.label_mon_dossier
																						)}
																					</option>
																					<option
																						value={CommunicationMeanEnum.EMAIL}
																					>
																						{formatStrapiText(
																							pageData?.assets?.label_email
																						)}
																					</option>
																					<option
																						value={
																							CommunicationMeanEnum.TELEPHONE_DAY
																						}
																					>
																						{formatStrapiText(
																							pageData?.assets
																								?.label_telephoneDay
																						)}
																					</option>
																					<option
																						value={
																							CommunicationMeanEnum.TELEPHONE_EVENING
																						}
																					>
																						{formatStrapiText(
																							pageData?.assets
																								?.label_telephoneEvening
																						)}
																					</option>
																					<option
																						value={
																							CommunicationMeanEnum.TELEPHONE_OTHER
																						}
																					>
																						{formatStrapiText(
																							pageData?.assets
																								?.label_telephoneOther
																						)}
																					</option>
																				</select>
																			</>
																		)}
																	</Field>
																</fieldset>
																<fieldset>
																	<Field name="emailNotification">
																		{() => (
																			<div>
																				<label
																					className={
																						formUtils.classes.formLabel
																					}
																				>
																					{formatStrapiText(
																						pageData.assets
																							?.label_emailNotification
																					)}
																				</label>
																				<div
																					className={
																						formUtils.classes.radioWrapper
																					}
																				>
																					<input
																						type="radio"
																						id="emailNotificationYes"
																						value={`${EMAIL_NOTIFICATION_OPTION.YES}`}
																						className={
																							formUtils.classes.radioInput
																						}
																						checked={
																							values.emailNotification ===
																							EMAIL_NOTIFICATION_OPTION.YES
																						}
																						onChange={(e) => {
																							setFieldValue(
																								'emailNotification',
																								EMAIL_NOTIFICATION_OPTION.YES
																							)

																							handleOnChange(
																								EMAIL_NOTIFICATION_OPTION.YES,
																								'emailNotification',
																								setFieldValue
																							)
																						}}
																					/>
																					<label
																						htmlFor="emailNotificationYes"
																						className={
																							formUtils.classes.radioLabel
																						}
																					>
																						{formatStrapiText(
																							pageData.assets?.label_yes
																						)}
																					</label>
																				</div>
																				<div
																					className={
																						formUtils.classes.radioWrapper
																					}
																				>
																					<input
																						type="radio"
																						id="emailNotificationNo"
																						value={`${EMAIL_NOTIFICATION_OPTION.NO}`}
																						className={
																							formUtils.classes.radioInput
																						}
																						checked={
																							values.emailNotification ===
																							EMAIL_NOTIFICATION_OPTION.NO
																						}
																						onChange={(e) => {
																							setFieldValue(
																								'emailNotification',
																								EMAIL_NOTIFICATION_OPTION.NO
																							)
																							handleOnChange(
																								EMAIL_NOTIFICATION_OPTION.NO,
																								'emailNotification',
																								setFieldValue
																							)
																						}}
																					/>
																					<label
																						htmlFor="emailNotificationNo"
																						className={
																							formUtils.classes.radioLabel
																						}
																					>
																						{formatStrapiText(
																							pageData.assets?.label_no
																						)}
																					</label>
																				</div>
																			</div>
																		)}
																	</Field>
																</fieldset>
															</div>
														</section>
													</div>
													<div
														className={joinClasses([pageUtils.classes.small])}
													>
														<div className={pageUtils.classes.blueBackground}>
															<h3 className={pageUtils.classes.h3}>
																{formatStrapiText(
																	pageData.assets
																		?.profilePage_communicationsSubscription_title
																)}
															</h3>
															<div>
																<p>
																	{formatStrapiText(
																		pageData.assets
																			?.profilePage_communicationsSubscription_description
																	)}
																</p>
																<a
																	target="_blank"
																	href={
																		language == LanguageEnum.FR
																			? 'https://infolettre.laval.ca/T/WF/14321/eewxbf/Optin/fr-CA/Form.ofsys'
																			: 'https://infolettre.laval.ca/T/WF/14321/eewxbf/Optin/en-CA/Form.ofsys'
																	}
																	className={joinClasses([
																		pageUtils.classes.buttonOutline,
																		pageUtils.classes.mainBtn
																	])}
																>
																	{formatStrapiText(
																		pageData.assets?.label_subscribe
																	)}
																</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										)}
										{activeTab === TAB.PROPERTY && (
											<div className="noBackground">
												<MyProperty />
											</div>
										)}
										{activeTab !== TAB.PROPERTY && (
											<fieldset
												className={pageUtils.classes.submissionContainer}
											>
												<div className={formUtils.classes.buttonContainer}>
													{!isSubmitting ? (
														<ButtonLink
															text={
																location.state?.form
																	? formatStrapiText(
																			pageData.assets?.button_save_and_continue
																	  )
																	: formatStrapiText(
																			pageData.assets?.button_save
																	  )
															}
															onclick={() => {
																setHasCancel(false)
															}}
															type="button"
															disabled={!edited}
															wrapperClassName={formUtils.classes.buttonWrapper}
														/>
													) : (
														<ButtonLink
															text="..."
															type="button"
															disabled={!edited}
															wrapperClassName={formUtils.classes.buttonWrapper}
														/>
													)}
												</div>
												<div>
													<button
														className={joinClasses([
															pageUtils.classes.buttonOutline,
															pageUtils.classes.mainBtn
														])}
														style={{
															cursor: isSubmitting
																? 'not-allowed'
																: !edited
																? 'default'
																: 'pointer',
															background: !edited ? Colors.lightGrey6 : '',
															border: !edited ? Colors.white : '',
															color: !edited ? Colors.white : ''
														}}
														type="button"
														onClick={() => {
															if (!isSubmitting) {
																// if (location.state?.form) {
																// 	navigate(location.state?.url + '?step=2', {
																// 		state: {
																// 			step: 2
																// 		}
																// 	})

																// 	return
																// }
																setHasCancel(true)
																setAddressSaved(authUser?.profile?.address)
																resetForm()
															}
														}}
														disabled={!edited}
													>
														{formatStrapiText(pageData.assets?.button_cancel)}
													</button>
												</div>
											</fieldset>
										)}
										<FormikErrorFocus
											formik={{} as any}
											offset={-108}
											align={'top'}
											focusDelay={200}
											ease={'linear'}
											duration={10}
										/>
									</Form>
								</>
							)}
						</Formik>
					</div>
				</div>
			</section>
		</>
	)
}

export default Profile
